import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
// import Img from 'gatsby-image';

import { hasImage } from '@utils/helpers';
import { Container, ButtonGroup } from '@elements';
import BlockContentSimple from '@components/BlockContentSimple';

const HeroSubpage = ({ data, rawData }) => {
  const imageExists = hasImage(data.image);

  return (
    <HeroSubPageWrap className="color-mode-background-200 relative overflow-hidden min-h-1/2vh grid items-center">
      {imageExists && (
        <>
          <Img
            className="absolute top-0 left-0 object-cover object-center w-full h-full"
            fluid={data.image.asset.fluid}
            alt={data.image.alt}
          />
          <div
            className={`absolute top-0 left-0 w-full h-full bg-black opacity-${
              Number.isInteger(data.coverOpacity) ? data.coverOpacity : '50'
            } pointer-events-none`}
          />
        </>
      )}
      <Container
        className={`z-20 relative ${
          imageExists && 'color-mode-dark'
        } bg-transparent`}
      >
        <div className="text-center">
          <h1>
            <BlockContentSimple
              blocks={rawData.textLarge || []}
              className={`${imageExists && 'text-white'} leading-10`}
            />
          </h1>

          <SmallText className="mt-3 mb-0 max-w-md mx-auto text-xl sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            <BlockContentSimple
              blocks={rawData.textSmall || []}
              className={`${imageExists && 'text-white'}`}
            />
          </SmallText>

          <ButtonGroup
            buttons={data.buttons}
            hasImageAsBackground={imageExists}
            className=""
          />
        </div>
      </Container>
    </HeroSubPageWrap>
  );
};

export default HeroSubpage;

const HeroSubPageWrap = styled.section``;

const SmallText = styled.div`
  & p:last-child {
    margin-bottom: 0;
  }
`;

export const HeroSubPageFragment = graphql`
  fragment HeroSubPageFragment on SanityHeroSubPage {
    _key
    _type
    buttons {
      ...ButtonFragment
    }
    coverOpacity
    image {
      asset {
        _id
        fluid(maxWidth: 1600) {
          ...GatsbySanityImageFluid
        }
      }
      alt
      caption
    }
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;

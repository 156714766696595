import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
// import Img from 'gatsby-image';

import { hasImage } from '@utils/helpers';
import { Container, ButtonGroup } from '@elements';
import BlockContentSimple from '@components/BlockContentSimple';

const BannerSmall = ({ data, rawData }) => {
  const imageExists = hasImage(data.image);

  return (
    <BannerSmallWrap
      className={`${
        data.backgroundColor &&
        (data.backgroundColor === 'primary' ||
          data.backgroundColor === 'secondary')
          ? `bg-${data.backgroundColor} color-mode-light`
          : ''
      } ${
        !data.backgroundColor || data.backgroundColor === 'inherit'
          ? 'color-mode-toggle'
          : ''
      } ${imageExists ? 'color-mode-dark' : ''} relative overflow-hidden`}
    >
      {imageExists && (
        <>
          <Img
            className="absolute top-0 left-0 object-cover object-center w-full h-full"
            fluid={data.image.asset.fluid}
            alt={data.image.alt}
          />
          <div
            className={`absolute top-0 left-0 w-full h-full bg-black opacity-${
              Number.isInteger(data.coverOpacity) ? data.coverOpacity : '50'
            } pointer-events-none`}
          />
        </>
      )}
      <Container className="z-10 relative max-w-screen-xl mx-auto text-center space-y-6 py-10 px-4 sm:px-6 lg:py-20 lg:px-8 lg:flex lg:items-center lg:justify-between lg:space-x-6 lg:text-left lg:space-y-0">
        <div
          className={`tracking-tight text-xl md:text-2xl lg:text-3xl font-extrabold ${
            data.backgroundColor === 'secondary' && 'text-white'
          }`}
        >
          <BlockContentSimple
            blocks={rawData.text || []}
            className={`${imageExists && 'text-white'}`}
          />
        </div>

        <ButtonGroupStyled
          buttons={data.buttons}
          backgroundColor={data.backgroundColor}
          hasImageAsBackground={imageExists}
          className=""
        />
      </Container>
    </BannerSmallWrap>
  );
};

export default BannerSmall;

const BannerSmallWrap = styled.section``;

const ButtonGroupStyled = styled(ButtonGroup)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;

export const BannerSmallFragment = graphql`
  fragment BannerSmallFragment on SanityBannerSmall {
    _key
    _type
    backgroundColor
    buttons {
      ...ButtonFragment
    }
    coverOpacity
    image {
      asset {
        _id
        fluid(maxWidth: 1600) {
          ...GatsbySanityImageFluid
        }
      }
      alt
      caption
    }
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;

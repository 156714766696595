import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ContentNarrow from '@components/ContentNarrow';

import { Container } from '@elements';

import { TeamMember, TeamMemberWithLink } from './TeamMember';

const TeamMembers = ({ data, rawData }) => {
  const teamData = useStaticQuery(graphql`
    query {
      sanityTeamSettings {
        createPageForEach
        team {
          _rawBio(resolveReferences: { maxDepth: 10 })
          pageInfo {
            slug {
              current
            }
            name
          }
          _id
          _key
          image {
            asset {
              _id
            }
          }
          isActive
          role
          socialMediaHandles {
            ...SocialMediaHandlesFragment
          }
        }
      }
    }
  `);

  const { createPageForEach, team } = teamData.sanityTeamSettings;

  return (
    <div className="color-mode-background-200">
      <ContentNarrow rawData={rawData.content} className="pb-0" />
      <Container className="">
        <div
          className={`mt-12 grid gap-8 mx-auto sm:grid-cols-${
            team.length > 2 ? 2 : team.length
          } lg:grid-cols-${team.length > 3 ? 3 : team.length} xl:grid-cols-${
            team.length > 4 ? 4 : team.length
          } lg:max-w-none`}
        >
          {createPageForEach ? (
            <>
              {team.map((member) => (
                <TeamMemberWithLink
                  className="max-w-3xl"
                  key={member._id}
                  data={member}
                />
              ))}
            </>
          ) : (
            <>
              {team.map((member) => (
                <TeamMember
                  className="max-w-3xl"
                  key={member._id}
                  data={member}
                />
              ))}
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default TeamMembers;

export const TeamMembersFragment = graphql`
  fragment TeamMembersFragment on SanityTeamMembers {
    _key
    _type
    buttons {
      ...ButtonFragment
    }
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;

import React from 'react';
import { graphql } from 'gatsby';

import CollectionBox from '@components/CollectionBox';
import BlockContent from '@components/BlockContent';

import { Container } from '@elements';

const CollectionBoxes = ({ data, rawData }) => (
  <Container>
    <div className="text-center">
      <BlockContent blocks={rawData.content || []} />
    </div>
    <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
      {data.boxes?.length &&
        data.boxes.map((box) => <CollectionBox key={box._key} data={box} />)}
    </div>
  </Container>
);

export default CollectionBoxes;

export const CollectionBoxesFragment = graphql`
  fragment CollectionBoxesFragment on SanityCollectionBoxes {
    _key
    _type
    boxes {
      ...CollectionBoxFragment
    }
    buttons {
      ...ButtonFragment
    }
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;

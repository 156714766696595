import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';

import ContentNarrow from '@components/ContentNarrow';

import { Container, ButtonGroup } from '@elements';

import Post from './Post';

const Posts = ({ data, rawData }) => {
  const postsQuery = useStaticQuery(graphql`
    query {
      posts: allSanityPost {
        edges {
          node {
            _id
            _rawExcerpt(resolveReferences: { maxDepth: 10 })
            _rawBody(resolveReferences: { maxDepth: 10 })
            author {
              _id
              pageInfo {
                name
                slug {
                  current
                }
              }
              image {
                asset {
                  _id
                  fluid(maxWidth: 80) {
                    ...GatsbySanityImageFluid
                  }
                }
                alt
                caption
              }
            }
            _rawAuthor
            categories {
              _id
              pageInfo {
                slug {
                  current
                }
                name
              }
            }
            excerpt
            image {
              asset {
                _id
                fluid(maxWidth: 1600) {
                  ...GatsbySanityImageFluid
                }
              }
              alt
              caption
            }
            pageInfo {
              slug {
                current
              }
              name
            }
            publishedAt(formatString: "MMMM DD, YYYY")
            dateReadable: publishedAt(formatString: "MMMM DD, YYYY")
            date: publishedAt(formatString: "YYYY-MM-DD")
            seo {
              ...SeoFragment
            }
            subTitle
            readTime
            tags
          }
        }
      }
    }
  `);

  const postsObj = postsQuery.posts.edges;
  const postsArr = [];
  const selectedPosts = [];

  if (data.posts && data.posts.length > 0) {
    data.posts.forEach((indPost) => {
      selectedPosts.push(indPost._id);
    });
  }

  postsObj.forEach((edge) => {
    postsArr.push(edge.node);
  });

  const sortedPosts = _.orderBy(postsArr, 'publishedAt', 'desc');
  const recentPosts = sortedPosts.slice(0, 3);

  return (
    <div className="color-mode-background-200">
      <ContentNarrow rawData={rawData} textNode="content" />
      <Container className="justify-center grid">
        <div
          className={`grid gap-8 lg:grid-cols-${
            data.posts && data.posts.length
              ? data.posts.length
              : recentPosts.length
          }`}
        >
          {data.posts && data.posts.length > 0 ? (
            <>
              {sortedPosts.map((post, i) => {
                if (selectedPosts.includes(post._id)) {
                  return (
                    <Post className="max-w-sm" key={post._id} post={post} />
                  );
                }
                return null;
              })}
            </>
          ) : (
            <>
              {recentPosts.map((post, i) => {
                if (i < 3) {
                  return (
                    <Post className="max-w-sm" key={post._id} post={post} />
                  );
                }
                return null;
              })}
            </>
          )}
        </div>
        <ButtonGroup buttons={data.buttons} className="" />
      </Container>
    </div>
  );
};

export default Posts;

export const PostsFragment = graphql`
  fragment PostsFragment on SanityPosts {
    _key
    _type
    buttons {
      ...ButtonFragment
    }
    posts {
      _id
    }
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;

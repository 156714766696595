import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ContentNarrow from '@components/ContentNarrow';
import { Image, Button } from '@elements';

function SampleNextArrow(props) {
  const { className, style, onClick, extraClassName } = props;
  return (
    <SlickButton
      className={`${className} ${extraClassName}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      type="button"
      name="next"
      aria-label="Next Photo"
    >
      <svg fill="currentColor" viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </SlickButton>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick, extraClassName } = props;
  return (
    <SlickButton
      className={`${className} ${extraClassName}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      type="button"
      name="previous"
      aria-label="Previous Photo"
    >
      <svg fill="currentColor" viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
          clipRule="evenodd"
        />
      </svg>
    </SlickButton>
  );
}

const GallerySlider = ({ data, rawData }) => {
  const settings = {
    // customPaging(i) {
    //   return (
    //     <a>
    //       {/* <img src={`${baseUrl}/abstract0${i + 1}.jpg`} /> */}
    //       <Image
    //         image={data.images[i]}
    //         hideCaption
    //         fixedProps={{ width: 100 }}
    //         imageClasses="rounded-lg shadow-lg"
    //       />
    //     </a>
    //   );
    // },
    // dots: true,
    // dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    nextArrow: (
      <SampleNextArrow extraClassName="text-gray-900 bg-white rounded-full p-6 z-10 flex justify-center align center w-auto h-auto transition duration-300 hover:bg-gray-900 hover:text-white" />
    ),
    prevArrow: (
      <SamplePrevArrow extraClassName="text-gray-900 bg-white rounded-full p-6 z-10 flex justify-center align center w-auto h-auto transition duration-300 hover:bg-gray-900 hover:text-white" />
    ),
  };

  return (
    <>
      <div id={data?.anchor?.current} />
      <ContentNarrowStyled className="pt-0 my-20 pb-0">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div>
            {data.subHeading && (
              <>
                <span className="text-base leading-6 font-semibold uppercase tracking-wider">
                  {data.subHeading}
                </span>
                {data.heading && <h2>{data.heading}</h2>}
              </>
            )}
          </div>
          {data?.button?.link && (
            <div>
              <Button
                buttonObject={data.button}
                className="w-full mb-8 sm:w-auto sm: mb-0"
              />
            </div>
          )}
        </div>

        {data.images && data.images.length && (
          <LazyLoadComponent>
            <SliderStyled {...settings}>
              {data.images.map((image, i) => (
                <div key={image._key}>
                  <Image image={image} />
                </div>
              ))}
            </SliderStyled>
          </LazyLoadComponent>
        )}
      </ContentNarrowStyled>
    </>
  );
};

export default GallerySlider;

const SlickButton = styled.button`
  &.slick-prev {
    left: -40px;
  }

  &.slick-next {
    right: -40px;
  }

  &:before {
    display: none;
  }

  svg {
    width: 30px;
  }
`;

const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
    bottom: 0;
    margin-top: 2rem;

    li {
      width: auto;
    }
  }
`;

const ContentNarrowStyled = styled(ContentNarrow)`
  &:before {
    display: block;
    height: 140px;
    margin-top: -140px;
    visibility: hidden;
    content: '';
  }
`;

export const GallerySliderFragment = graphql`
  fragment GallerySliderFragment on SanityGallerySlider {
    _key
    _type
    heading
    anchor {
      current
    }
    button {
      ...ButtonFragment
    }
    images {
      asset {
        _id
      }
      alt
      caption
    }
    subHeading
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;

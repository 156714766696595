import React from 'react';
import { graphql } from 'gatsby';

import { Link, Image } from '@elements';

import { slugifyModified } from '@utils';

const CollectionBoxes = ({ data, className }) => {
  const createLinkObject = {
    link: data.link,
    anchor: {
      current: data.anchor ? slugifyModified(data.anchor) : '',
    },
  };

  if (data?.image?.alt) {
    data.image.alt = data.heading || "";
  }

  return (
    <Link
      className={`flex flex-col rounded-lg shadow-lg overflow-hidden color-mode-background text-left no-underline transition duration-300 linear transform hover:-translate-y-1 hover:shadow-2xl ${className}`}
      link={createLinkObject}
    >
      <div className="flex-shrink-0">
        {data?.image?.asset?._id && (
          <Image
            className=""
            image={data.image}
            imageClasses="w-full h-72 object-cover object-center"
          />
        )}
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between w-full">
        <div className="flex-1">
          <h3 className="text-xl text-center mb-0">{data.heading}</h3>
        </div>
      </div>
    </Link>
  );
};

export default CollectionBoxes;

export const CollectionBoxFragment = graphql`
  fragment CollectionBoxFragment on SanityCollectionBox {
    _key
    heading
    anchor
    image {
      asset {
        _id
      }
    }
    link {
      ...InternalLinkFragment
    }
  }
`;

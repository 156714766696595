import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import ContentNarrow from '@components/ContentNarrow';

const UpToEmbed = ({ data, rawData }) => (
  <>
    {data?.show && (
      <ContentNarrow className="pt-0 my-20 pb-0">
        <Helmet>
          <script>
            {`
          !function(c,b,a){function d(h){var e=document.getElementById("upto-calendar-container-"+c),f=document.createElement("SCRIPT"),g="https://upto.com/js/plugin.js";f.onreadystatechange=function(){if(f.readyState==="loaded"||f.readyState==="complete"){f.onreadystatechange=null;h()}};f.onload=function(){h()};f.setAttribute("src",g);e.parentNode.insertBefore(f,e)}d(function(){upto.init(c)})}("QKKO");
        `}
          </script>
        </Helmet>
        <div
          id="upto-calendar-container-QKKO"
          className="upto-calendar-plugin"
        />
      </ContentNarrow>
    )}
  </>
);

export default UpToEmbed;

export const UpToEmbedFragment = graphql`
  fragment UpToEmbedFragment on SanityUpToEmbed {
    _key
    _type
    show
  }
`;

import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
// import Img from 'gatsby-image';

import { media } from '@utils';
import { hasImage, lineBreakToBr } from '@utils/helpers';
import { Container, Button } from '@elements';

const HeroHomepage = ({ data, rawData }) => {
  const imageExists = hasImage(data.image);

  return (
    <HeroHomepageWrap className="relative overflow-hidden bg-gray-900 flex items-center">
      {imageExists && (
        <>
          <Img
            className="absolute top-0 left-0 object-cover object-center w-full h-full"
            fluid={data.image.asset.fluid}
            alt={data.image.alt}
          />
          <div
            className={`absolute top-0 left-0 w-full h-full bg-black opacity-${
              Number.isInteger(data.coverOpacity) ? data.coverOpacity : '50'
            } pointer-events-none`}
          />
        </>
      )}
      <Container className="relative flex items-center justify-center h-full text-center">
        <div className=" lg:w-1/2 space-y-6">
          <div className="flex flex-col space-y-6">
            <span className="text-6xl font-bold leading-none text-white lg:text-8xl font-secondary text-shadow-8xl">
              {lineBreakToBr(data.textLarge)}
            </span>
            <span className="text-2xl text-white lg:text-3xl font-semiBold font-secondary text-shadow-3xl">
              {data.textSmall}
            </span>
          </div>
          <div className="mt-3">
            {data.buttons &&
              data.buttons.map((button) => (
                <Button
                  key={button._key}
                  buttonObject={button}
                  className={`w-3/4 mb-3 sm:mr-3 sm:mb-0 shadow-none sm:w-auto ${
                    button.isGhost
                      ? 'btn-ghost border-white text-white hover:border-primary hover:bg-primary'
                      : ''
                  }`}
                />
              ))}
          </div>
        </div>
      </Container>
    </HeroHomepageWrap>
  );
};

export default HeroHomepage;

const HeroHomepageWrap = styled.section`
  min-height: 50vh;

  ${media.lg`
  min-height: 80vh;
  `}
`;

export const HeroHomepageFragment = graphql`
  fragment HeroHomepageFragment on SanityHeroHomepage {
    _key
    _type
    buttons {
      ...ButtonFragment
    }
    coverOpacity
    image {
      asset {
        _id
        fluid(maxWidth: 1600) {
          ...GatsbySanityImageFluid
        }
      }
      alt
      caption
    }
    textLarge
    textSmall
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;
